<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The vapor pressure of liquid
        <chemical-latex content="SO2" />
        is
        <stemble-latex content="$2232\,\text{Pa}$" />
        at
        <stemble-latex content="$201\,\text{K}$" />
        and
        <stemble-latex content="$\Delta_\text{vap}\text{H}\,=\,24.94\,\text{kJ/mol.}$" />
      </p>

      <p class="mb-2">
        a) What is the vapor pressure of
        <chemical-latex content="SO2" />
        at
        <number-value :value="temp" unit="\text{K?}" />
      </p>

      <calculation-input
        v-model="inputs.inputA"
        prepend-text="$\text{P}_\ce{SO2}:$"
        append-text="$\text{Pa}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) At what temperature will
        <chemical-latex content="SO2" />
        have a vapor pressure of
        <number-value :value="pressure" unit="\text{Pa}" />
      </p>

      <calculation-input
        v-model="inputs.inputB"
        prepend-text="$\text{T:}$"
        append-text="$\text{K}$"
        class="mb-0"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1210S4_Q10',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputA: null,
        inputB: null,
      },
    };
  },
  computed: {
    temp() {
      return this.taskState.getValueBySymbol('temp').content;
    },
    pressure() {
      return this.taskState.getValueBySymbol('pressure').content;
    },
  },
};
</script>
